/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"

import RichText from "../components/rich-text"
import SEO from "../components/seo"
import Container from "../components/container"
// import SpecificationsOverview from "../components/specifications-overview"
import CTA from "../components/cta"

const JobDetail = ({ data, location }) => {
  let job = data.contentfulJobListing
  return (
    <React.Fragment>
      <SEO
        title={job.jobTitle}
        description={get(job, "metaDescription.metaDescription", "")}
      />
      <Container
        customStyle={{
          py: theme => theme.spacing.vertical.sm,
          pl: ["15px", "", ""],
        }}
      >
        <h5>{"Job Listing"}</h5>
        <h2>{job.jobTitle}</h2>
        {job.location && <h5 sx={{ pb: 3 }}>{job.location}</h5>}
        <RichText data={job.jobDescription} />
        {job.url && job.url.url && (
          <CTA link={job.url.url} customStyle={{ mt: 3 }} linkType="external">
            {"Apply Now"}
          </CTA>
        )}
      </Container>
    </React.Fragment>
  )
}

export default JobDetail

export const query = graphql`
  query($slug: String!) {
    contentfulJobListing(slug: { eq: $slug }) {
      url: childContentfulJobListingUrlTextNode {
        url
      }
      jobTitle
      location
      metaDescription: childContentfulJobListingMetaDescriptionTextNode {
        metaDescription
      }
      jobDescription: childContentfulJobListingJobDescriptionRichTextNode {
        json
      }
    }
  }
`
